import PropTypes from 'prop-types'
import React from 'react'

const Paragraph = ({ children }) => (
  <p
    className={`
      mb-5
      text-blue-900
      w-full
    `}
  >
    {children}
  </p>
)

export default Paragraph

Paragraph.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'

import Headline from '../elements/headline'
import Paragraph from '../elements/paragraph'

const PodloveSimpleChapters = () => (
  <Layout
    hero={[
      'Podlove Simple Chapters',
      'Community-backed specifications that solve real problems of podcasters, podcast client and podcast users alike.'
    ]}
  >
    <Seo title="Specifications" />
    <div
      className="
        flex
        flex-wrap
        items-stretch
        ml-auto
        mr-auto
        mt-16
        max-w-full
        md:max-w-2xl
        px-4
      "
    >
      <Paragraph>Podlove Simple Chapters is an XML 1.0 based format meant to extend file formats like Atom Syndication <a className="text-turquoise-500 font-semibold" href="#footnote_atom">[1]</a> and RSS 2.0 <a className="text-turquoise-500 font-semibold" href="#footnote_rss">[2]</a> that reference enclosures (podcasts). As the name implies, this format defines simple chapter structures in media files.</Paragraph>
      <Headline level={2}>Motivation and Scope</Headline>
      <Paragraph>Chapters are an increasingly asked-for feature for long media files. Talk shows and interviews spanning multiple hours and addressing lots of different topics are very common these days and chapters make things easier for the listener to either fast forward to certain topics or to recap stuff already listened to.</Paragraph>
      <Paragraph>Chapter marks are available in MPEG–4 media files, the only “standard” being Apple’s way of tagging media files that has been reengineered by some developers but is still officially undocumented. Chapter marks are also supported in MP3 [3] and Ogg [4] media files. Support for these features slowly made its way into podcasting clients (MP3 chapters being supported while postprocessing tools/services like Auphonic provide universal support for chapters for all media container types.</Paragraph>
      <Paragraph>While chapter support in media files is a good thing, it still adds a significant amount of work for both producers and app developers to provide a complete implementation. This is where Podlove Simple Chapters come in.</Paragraph>
      <Paragraph>Placing the information in podcast feeds not only allows easy extraction of chapter information for podcast clients regardless of the media file types in use, but also enables systems to display this information before the media files themselves have been downloaded.</Paragraph>
      <Paragraph>Simple Chapters does not try to be a comprehensive solution for all kinds of possible timeline meta data. It neither allows links to be placed independent from chapters nor does it reference images in the timeline (both features that would be supported by popular media players like iPod or iPhone).</Paragraph>
      <Paragraph>The format simply expresses a straightforward, non-hierarchical linear structure by linking chapter titles and an optional hypertext reference to specific points in time. It is intended to be easy to author, easy to parse and easy to present to the user.</Paragraph>
      <Headline level={2}>XML Namespace</Headline>
      <Paragraph>The XML namespace URI for this format is</Paragraph>
      <Paragraph><code>http://podlove.org/simple-chapters</code></Paragraph>
      <Paragraph>Please note: Examples included here use the namespace prefix “psc:” but the XML standard allows you to use any namespace prefix you prefer.</Paragraph>
      <Headline level={2}>Structure</Headline>
      <Paragraph>The Simple Chapters specification defines two elements: <code>&lt;psc:chapters&gt;</code> and <code>&lt;psc:chapter&gt;</code>.</Paragraph>
      <Headline level={3} color="blue-400"><code>&lt;psc:chapters&gt;</code></Headline>
      <Paragraph>The <code>&lt;psc:chapters&gt;</code> element defines a simple chapter list within an <code>&lt;atom:entry&gt;</code> element in an Atom <a className="text-turquoise-500 font-semibold" href="#footnote_atom">[1]</a> feed or within an &lt;item&gt; element within an RSS 2.0 <a className="text-turquoise-500 font-semibold" href="#footnote_rss">[2]</a> channel.</Paragraph>
      <Paragraph>There must be only one <code>&lt;psc:chapters&gt;</code> element. Clients should ignore extra <code>&lt;psc:chapters&gt;</code> elements. The <code>&lt;psc:chapters&gt;</code> elements contains one or more <code>&lt;psc:chapter&gt;</code> elements.</Paragraph>
      <Paragraph>The <code>&lt;psc:chapters&gt;</code> element accepts one attribute:</Paragraph>
      <dl
        className="
          text-blue-600
          bg-blue-100
          rounded-lg
          py-6
          mb-10
          px-4
          w-full
          space-y-2
        "
      >
        <dt><code>version=“number”</code></dt>
        <dd>Specifies the version of this specification (current version is 1.1). This enables future enhancements of this standard. This attribute is optional and defaults to “1.0”.</dd>
      </dl>
      <Headline level={3} color="blue-400"><code>&lt;psc:chapter&gt;</code></Headline>
      <Paragraph>The <code>&lt;psc:chapter&gt;</code> element defines a single chapter mark within an associated media file. It does so by stating the absolute time. It does not contain any media file dependent links to specific byte ranges within that file.</Paragraph>
      <Paragraph>The <code>&lt;psc:chapter&gt;</code> element accepts the following attributes:</Paragraph>
      <dl
        className="
          text-blue-600
          bg-blue-100
          rounded-lg
          py-6
          px-4
          w-full
          mb-10
          space-y-2
        "
      >
        <dt><code>start=&quot;time&quot;</code></dt>
        <dd>Refers to a single point in time relative to the beginning of the media file. The syntax of time is defined below. This attribute is mandatory.</dd>
        <dt><code>title=&quot;name&quot;</code></dt>
        <dd>Defines name to be the title of the chapter. The title could be any length, but authors should consider the titles to be displayed on small devices with limited screen estate. Also, shorter titles are easier to grasp. This attribute is mandatory.</dd>
        <dt><code>href=&quot;link&quot;</code></dt>
        <dd>This is an additional hypertext reference as an extension of the title that refers to a resource that provides related information. This link should be presented to the user only in context with the chapter and its title. This attribute is optional.</dd>
        <dt><code>image=&quot;url&quot;</code></dt>
        <dd>This is an URL pointing to an image to be associated with the chapter. This attribute is optional.</dd>
      </dl>
      <Paragraph>The list of <code>&lt;psc:chapter&gt;</code> elements within an <code>&lt;psc:chapters&gt;</code> element should be in the order of the value of their time attribute but clients must be able to  resort the list based on time when presenting it to the user.</Paragraph>
      <Headline level={2}>Time</Headline>
      <Paragraph>The time is expressed in Normal Play Time [5] which allows for a flexible input of time markers. Here are some examples that show how the time field can be used:</Paragraph>
      <dl
        className="
          text-blue-600
          bg-blue-100
          rounded-lg
          py-6
          px-4
          mb-10
          w-full
          space-y-2
        "
      >
        <dt><code>01:35:52</code></dt>
        <dd>1 hour 35 minutes 52 seconds</dd>
        <dt><code>7:48</code></dt>
        <dd>7 minutes, 48 seconds</dd>
        <dt><code>35:12.250</code></dt>
        <dd>35 minutes, 12 seconds, 250 ms</dd>
        <dt><code>05:12:03.5</code></dt>
        <dd>5 hours, 12 minutes, 3 seconds, 500 ms</dd>
        <dt><code>37</code></dt>
        <dd>37 seconds</dd>
      </dl>
      <Headline level={2}>Embedding Example</Headline>
      <Paragraph>This is an simple example of a RSS podcast feed with embedded chapter information in Podlove Simple Chapter format:</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      >
        <code>
          {`<?xml version="1.0" encoding="utf-8"?>
<rss version="2.0" xmlns:atom="http://www.w3.org/2005/Atom">
  <channel>
    <title>Podlove Podcast</title>
    <atom:link type="text/html" href="http://podlove.org/" />
    <item>
      <title>Fiat Lux</title>
      <link href="http://podlove.org/podcast/1"/>
      <guid isPermaLink="false">urn:uuid:3241ace2-ca21-dd12-2341-1412ce31fad2</guid>
      <pubDate>Fri, 23 Mar 2012 23:25:19 +0000</pubDate>
      <description>First episode</description>
      <link rel="enclosure" type="audio/mpeg" length="12345" href="http://podlove.org/files/fiatlux.mp3"/>
      <!-- specify chapter information -->
      <psc:chapters version="1.2" xmlns:psc="http://podlove.org/simple-chapters">
        <psc:chapter start="0" title="Welcome" />
        <psc:chapter start="3:07" title="Introducing Podlove" href="http://podlove.org/" />
        <psc:chapter start="8:26.250" title="Podlove WordPress Plugin" href="http://podlove.org/podlove-podcast-publisher" />
        <psc:chapter start="12:42" title="Resumée" />
      </psc:chapters>
    </item>
  </channel>
</feed>`}
        </code>
      </pre>
      <Headline level={2}>External Chapter Information</Headline>
      <Paragraph>Instead of embedding the chapter information as described above, you can also use an <code>&lt;atom:link&gt;</code> Element to link to an external resource containing the chapter information. If you want to do this, replace the chapter information in the examples above simply by including the following in the <code>&lt;feed&gt;</code> (Atom) or <code>&lt;channel&gt;</code> (RSS 2.0) element of the feed:</Paragraph>
      <Paragraph>
        <code className="break-all">
          &lt;atom:link rel=&quot;http://podlove.org/simple-chapters&quot; href=&quot;http://podlove.org/examples/chapters.psc&quot; /&gt;
        </code>
      </Paragraph>
      <Paragraph>This is especially practicable to keep the feed size down or in situations where feed and chapter information are generated and/or updated separately.</Paragraph>
      <Headline level={2}>Other Podlove Specifications</Headline> 
      <Paragraph>These other specifications have been written so far:</Paragraph>
      <ul
        className="
          list-disc
          list-inside
          md:list-outside
          space-y-4
          pb-20
          max-w-full
        "
      >
        <li><a className="text-turquoise-500 font-semibold" href="/deep-links">Deep Linking</a> — Atom-based convention on how to convey web player information to a podcast client allowing deep linking into media content</li>
        <li><a className="text-turquoise-500 font-semibold" href="/podlove-simple-chapters">Podlove Alternate Feeds</a> — Best practice on how to relate to alternate feeds from within one podcast feed to give clients and users a choice of media formats and/or delivery methods</li>
        <li><a className="text-turquoise-500 font-semibold" href="/podlove-paged-feeds">Paged Feeds</a> — Using the RFC 5005 specification to span podcast feeds over multiple pages to keep subscription feeds small while retaining access to old episodes for the user to access.</li>
        <li id="footnote_atom">The Atom Syndication Format: <a className="text-turquoise-500 font-semibold" href="http://www.atomenabled.org/developers/syndication/atom-format-spec.php">http://www.atomenabled.org/developers/syndication/atom-format-spec.php</a>.</li>
        <li id="footnote_rss">RSS 2.0 Specification: <a className="text-turquoise-500 font-semibold" href="http://cyber.law.harvard.edu/rss/rss.html">http://cyber.law.harvard.edu/rss/rss.html</a>.</li>
        <li>ID3v2 Chapter Frame Addendum: <a className="text-turquoise-500 font-semibold" href="http://www.id3.org/id3v2-chapters-1.0">http://www.id3.org/id3v2-chapters-1.0</a>.</li>
        <li>VorbisComment Chapter Extension: <a className="text-turquoise-500 font-semibold" href="http://wiki.xiph.org/Chapter_Extension">http://wiki.xiph.org/Chapter_Extension</a>.</li>
        <li>Media Fragments URI 1.0 (basic): Temporal Dimension <a className="text-turquoise-500 font-semibold" href="http://www.w3.org/TR/media-frags/#naming-time">http://www.w3.org/TR/media-frags/#naming-time</a>.</li>
      </ul>
    </div>
  </Layout>
)

export default PodloveSimpleChapters
